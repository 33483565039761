import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DateAdapter } from '@angular/material/core';

import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { Languages } from 'src/definitions/language.enum';
import { getStorageObject, setStorageObject } from 'src/utils/storage-manager';

import { GoogleMapsLoaderService } from './google-maps-loader-service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  /**
   * Selected language
   */
  selectedLanguage: string | undefined;

  /**
   * Subject to notify the language change
   */
  languageChanged: Subject<string> = new Subject<string>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private translateService: TranslateService,
    private dateAdapter: DateAdapter<any>,
    private googleMapsLoaderService: GoogleMapsLoaderService
  ) {}

  listenToLanguageChanges(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.getLanguage();
      this.translateService.onLangChange.subscribe(() => {
        this.getLanguage();
      });
    }
  }

  private getLanguage(): void {
    if (!getStorageObject('language')) {
      this.selectedLanguage =
        this.translateService.getBrowserLang() === Languages.ES.toLowerCase() ? Languages.ES : Languages.EN;
      this.translateService.use(this.selectedLanguage.toLowerCase());
      this.languageChanged.next(this.selectedLanguage);
      this.dateAdapter.setLocale(this.selectedLanguage === Languages.ES ? 'es' : 'en');
      this.googleMapsLoaderService.reloadGoogleMapsScript(this.selectedLanguage.toLowerCase());
    } else {
      this.selectedLanguage = getStorageObject('language') === Languages.ES ? Languages.ES : Languages.EN;
      this.translateService.use(getStorageObject('language').toLowerCase());
      this.languageChanged.next(this.selectedLanguage);
      this.dateAdapter.setLocale(this.selectedLanguage === Languages.ES ? 'es' : 'en');
      this.googleMapsLoaderService.reloadGoogleMapsScript(this.selectedLanguage.toLowerCase());
    }
  }

  getLanguageKey(): string {
    return this.selectedLanguage || '';
  }

  selectLanguage(value: Languages): void {
    if (isPlatformBrowser(this.platformId)) {
      setStorageObject('language', value);
      this.translateService.use(value.toLowerCase());
      this.selectedLanguage = value;
      this.languageChanged.next(value);
      this.dateAdapter.setLocale(value === Languages.ES ? 'es' : 'en');
      this.googleMapsLoaderService.reloadGoogleMapsScript(this.selectedLanguage.toLowerCase());
    }
  }
}
