import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsLoaderService {
  constructor() {}

  reloadGoogleMapsScript(language: string): void {
    const existingScript = Array.from(document.getElementsByTagName('script')).find((script) =>
      script.src.includes('maps.googleapis.com/maps/api/js?')
    );

    if (existingScript) {
      existingScript.parentNode?.removeChild(existingScript);
    }

    const script = document.createElement('script');
    // eslint-disable-next-line max-len
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCiSDi0emvPnHueUnEtgP5P5eVGTnNLru4&language=${language}`;
    script.async = true;

    document.head.appendChild(script);
  }
}
